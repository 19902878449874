@use '../../variables.scss' as v;

.Logout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    h2, p {
        line-height: 0;
        padding: 0;
    }
    .ant-spin {
        margin-bottom: 24px;
    }
}