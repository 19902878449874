.ModuleSales {
    &__MainTable {
        
    }
    h4 {
        text-transform: lowercase;
        font-variant: small-caps;
        color: #999;
        margin: 0;
        padding: 0;
        margin-bottom: 16px;
        padding-left: 4px;
    }
    &--Container {
        padding: 16px;
    }
    form {
        .ant-input-data-count {
            font-size: 12px;
        }
        .ant-form-item{
            &-has-error{
                border-color: #ff4d4f;
            }
            &-explain-error{
                font-size: 14px;
                padding-left: 8px;
                padding-bottom: 8px;
            }
        }
    }
    &--Items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto auto;
        .ant-form-item {
            margin: 4px;
            border: 1px solid #CCC;
            border-radius: 6px;
            &:first-child {
                grid-column: 2;
            }
            &:nth-child(2) {
                grid-column: 0;
            }
            &-label {
                margin: 0;
                padding: 0;
                padding-top: 4px;
                label {
                    font-size: 12px;
                    width: 100%;
                    padding-left: 12px;
                    line-height: 0;
                    opacity: 0.75;
                    height: auto !important;
                }
            }
            &-control {
                &-input {
                    min-height: inherit !important;
                }
                .ant-picker {
                    border: 0;
                    width: 100%;
                }
                input, textarea, .ant-select-selector {
                    border: 0;
                    border-color: transparent;
                    border-radius: 0px;
                    margin-top: -2px;
                    font-weight: 500;
                    padding-top: 0;
                    margin-bottom: 0;
                    padding-bottom: 6px;
                    background: transparent;
                }
                .ant-select-selector {
                    input {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    &--Total {
        &--Label {
            font-weight: bold;
        }
        &--Net,
        &--Vat,
        &--Total {
            text-align: right;
        } 
    }
}

.clearfix {
    clear:both;
    grid-column: 3;
    min-height: 18px;
}

@media only screen and (max-width: 768px) {
    .ModuleSales {
        &--Items {
            display: flex;
            flex-direction: column;
            padding-bottom: 128px;
            h4 {
                margin: 0;
            }
        }
        &--ItemRow{
            display: flex !important;
            flex-direction: column;
            &--Finish {
                flex-direction: column;
                span.anticon{
                    width: 100%;
                    height: 32px;
                    background-color: #FF000050;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px;
                }
            }
        }
        &--Total {
            padding-top: 32px;
        }
    }
}