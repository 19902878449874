@use '../../variables.scss' as v;

.Login {
    &-Grid {
        height: 100vh;
        // height: calc(100vh - 48px);
        display: grid;
        grid-template-columns: 30% auto;
        grid-template-rows: 1fr;
        gap: 24px;

        &>section {
            background-image: url('../../Assets/Images/LoginSection__Background.png');
            background-color: v.$background-color;
            background-size: cover;
            background-position: bottom center;
            padding: 24px;
            margin: 24px;
            margin-right: 0px;
            border-radius: 24px;
            color: #FFF;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .align-top {
                flex: 1;
                align-items: flex-start;
            }

            h3 {
                font-weight: 400;
                font-size: 28px;
            }

            p {
                font-size: 18px;
                text-align: justify;
            }
        }

        &>main {
            margin: 24px;
            margin-left: 0px;
            padding: 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &>h2,
            &>p {
                margin: 0;
                padding: 0
            }

            &>p {
                margin-bottom: 16px;
            }

            & > form {
                button {
                    padding: 24px 32px !important;
                    line-height: 0px;
                }
            }
            // & > form {
            //   // min-width: min(300px, 100vw);
            // }
        }
    }
}

@media only screen and (max-width: 768px) {
    .Login {
        width: 100vw;

        &-Grid {
            grid-template-columns: auto;

            &>section {
                display: none;
            }
            form {
                max-width: 100% !important;
            }
        }
    }
}