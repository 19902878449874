@use '../../variables.scss' as v;

.AppFrame {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;

    &--FiltersBar {
        flex: 1;
        background-color: v.$background-color-10-force;
        min-height: 64px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 8px 24px;
        position: sticky;
        top: 0;
        z-index: 30;
    }

    &--Hide {
        position: absolute;
        // left: calc(80px + 200px + 64px - 16px - 1px);
        cursor: pointer;
        bottom: 16px;
        width: 32px;
        height: 32px;
        background: v.$background-color;
        border-radius: 32px;
        border: 2px solid #FFF;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 500ms;
        z-index: 20;
        &--Show {
            left: calc(80px + 200px + 64px - 16px - 1px);
        }
        &--Hide {
        left: calc(80px + 32px + 64px - 16px - 1px);
        }
    }

    &--Nav {
        background-color: v.$background-color;
        width: calc(80px - 32px);
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &--Block {
            padding: 24px 32px;
            padding-top: 0px;
            &--active, &--disabled:hover {
                opacity: 1;
                transition: all ease 300ms;
            }
            &--disabled {
                transition: all ease 300ms;
                opacity: 0.5;
            }

            &>h3 {
                font-variant: small-caps;
                text-transform: lowercase;
                color: v.$background-color;
                opacity: 0.5;
            }

            &>ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: column;
                gap: 18px;

                &>li {
                    padding: 0;
                    margin: 0;
                    cursor: pointer;

                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        text-overflow: "";
                    }

                    a {
                        text-decoration: none;
                        display: flex;
                        flex-direction: row;
                        gap: 12px;
                        color: #000;
                    }

                    svg {
                        color: v.$background-color;
                        width: 48px;
                    }

                    display: flex;
                    flex-direction: row;
                    gap: 6px;
                    align-items: center;
                }
            }
        }

        &>svg {
            cursor: pointer;
        }

        &>ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 16px;

            &>li {
                width: 100%;
                text-align: center;
                cursor: pointer;
                background-color: #00000000;
                padding: 12px 0px;
                display: flex;
                justify-content: center;

                &.disabled {
                    // pointer-events: none;
                    cursor: not-allowed;
                    opacity: 0.33;
                }

                &.selected {
                    background-color: #00000020;
                    border-radius: 6px;
                }
            }
        }

        &--Avatar {
            background-color: v.$primary-color;
            color: #FFF;
            font-weight: 500;
            width: 48px;
            height: 48px;
            border-radius: 48px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }

    &--SubOptions {
        background-color: v.$background-color-20;
        transition: all 500ms;
        &--Show {
            width: calc(200px + 64px);
        }
        &--Hide {
            width: calc(32px + 64px);
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            ul {
                gap: 24px;
            }
        }

        &>h2 {
            margin: 0;
            padding: 0;
            font-size: 16px;
            line-height: 0px;
            display: flex;
            align-items: center;
            text-overflow: "";
            text-wrap: nowrap;
            gap: 8px;
            color: v.$background-color;
            height: 32px;
            background-color: v.$background-color-10;
            padding: 24px;
        }
    }

    &--Main {
        flex: 1;
        padding: 16px;
        height: calc(100vh - 32px);
        overflow-y: auto;
        z-index: 10;
        &.NoPadding {
            padding: 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .AppFrame {
        flex-direction: column;
        &--Nav {
            width: calc(100vw - 32px);
            gap: 18px;
            align-items: center;
            ul {
                width: 90vw;
                flex-direction: row;
                li {
                    padding: 12px 32px;
                }
                display: none;
            }
            &--Avatar {
                display: none;
            }
        }
        &--SubOptions {
            width: 100vw;
            & > h2 {
                display: none;
            }
            ul {
                li {
                    padding: 12px 0px;
                }
            }
        }
        &--Hide{
            display: none;
        }
        &--FiltersBar {
            position: fixed;
            top: calc(100vh - 80px);
            width: calc(100vw - 48px);
            height: 80px;
            padding-top: 0px;
        }
    }
}